<template>
    <v-dialog v-model="queryDialog" fullscreen hide-overlay transition="dialog-bottom-transition">
        <template v-slot:activator="{ on }">
            <v-list-item v-if="listItem === true" v-on="on" @click="fetchColumnsForQuery(tableId)">
                <v-list-item-title>
                    <v-icon class="mr-1" small>filter_alt</v-icon>
                    Query table
                </v-list-item-title>
            </v-list-item>
            <v-btn
                v-else
                v-on="on"
                :class="['mr-1', buttonName === 'open querybuilder' ? 'caption font-weight-bold' : '']"
                :small="smallActivationButton"
                color="primary"
                text
                :outlined="outlinedActivationButton"
                @click="fetchColumnsForQuery(tableId)"
            >
                <v-icon small>filter_alt</v-icon>
                {{ buttonName }}
            </v-btn>
        </template>
        <v-card>
            <v-card-title class="title primary--text" primary-title>
                <div style="width:100%" class="d-flex justify-space-between align-center">
                    <div class="d-flex align-center">
                        <v-icon class="mr-1">filter_alt</v-icon>
                        <span class="text-uppercase">Table query</span>
                    </div>
                    <v-btn icon @click="queryDialog = false">
                        <v-icon>mdi-close</v-icon>
                    </v-btn>
                </div>
            </v-card-title>
            <v-divider></v-divider>
            <v-card-text class="mt-1">
                <v-stepper v-model="step" vertical color="secondary">
                    <v-stepper-step color="secondary" :complete="step > 1" step="1">Table and columns</v-stepper-step>
                    <v-stepper-content step="1">
                        <div>
                            <v-row align="center">
                                <v-col cols="12" sm="1">
                                    <v-subheader v-text="'From'"></v-subheader>
                                </v-col>
                                <v-col cols="12" sm="9">
                                    <span class="subtitle-1">{{ selectedTable }}</span>
                                </v-col>
                            </v-row>
                            <v-row align="center">
                                <v-col cols="12" sm="1">
                                    <v-subheader v-text="'Select'"></v-subheader>
                                </v-col>
                                <v-col cols="12" sm="9">
                                    <v-select
                                        v-model="selectedColumns"
                                        :items="columnData"
                                        label="Columns"
                                        :disabled="selectAllColumns || fetchingTableColumnsForQuery"
                                        multiple
                                        item-text="colName"
                                        item-value="colName"
                                        persistent-hint
                                        single-line
                                        :loading="fetchingTableColumnsForQuery"
                                    >
                                        <template v-slot:item="columnData">
                                            <div class="d-flex align-center py-2">
                                                <div class="mr-2" style="min-width:25px;height:25px;">
                                                    <v-icon v-if="filteredSelectedColumns.includes(columnData.item.colName)" color="success"
                                                        >mdi-check-circle</v-icon
                                                    >
                                                </div>
                                                <div class="d-flex flex-column">
                                                    <div class="d-flex align-center">
                                                        <span class="subtitle-2 font-weight-bold primary--text mr-1">{{ columnData.item.colName }} </span>
                                                        <v-chip outlined x-small
                                                            ><span class="overline">{{ columnData.item.type }}</span></v-chip
                                                        >
                                                    </div>

                                                    <span class="caption">{{ columnData.item.desc }}</span>
                                                </div>
                                            </div>
                                        </template>
                                        <template v-slot:prepend-item>
                                            <v-text-field autofocus v-model="columnSearch" class="px-4" label="Filter"></v-text-field>
                                        </template>
                                        <template v-if="selectAllColumns === true" v-slot:selection="{ item, index }">
                                            <span v-if="index === 0">{{ item.colName }}</span>
                                            <span class="mr-2" v-if="index === 1">(+{{ filteredSelectedColumns.length - 1 }} others)</span>
                                        </template>
                                    </v-select>
                                    <v-checkbox class="mt-3 mx-0 pa-0" v-model="selectAllColumns" label="Select all columns"></v-checkbox>
                                </v-col>
                            </v-row>
                        </div>
                        <v-btn :disabled="!selectedTable || !filteredSelectedColumns.length" color="secondary" @click="step = 2">continue</v-btn>
                    </v-stepper-content>
                    <v-stepper-step color="secondary" :complete="step > 2" step="2">Filters</v-stepper-step>
                    <v-stepper-content step="2">
                        <div class="mb-12">
                            <v-btn @click="addFilter" text class="mr-3 font-weight-bold" color="primary">+ add filter</v-btn>
                            <div v-if="addedFilters.length > 0">
                                <div v-for="(filter, index) in addedFilters" :key="filter.index" class="mb-2 pt-5">
                                    <v-form v-model="addedFilters[index].valid">
                                        <div class="d-flex align-center">
                                            <v-btn @click="removeFilter(filter.index)" small color="error" icon class="mr-3">
                                                <v-icon>mdi-delete-outline</v-icon>
                                            </v-btn>
                                            <v-row align="end" justify="start" flex-wrap no-gutters>
                                                <v-col class="mr-2" cols="2">
                                                    <v-select
                                                        @change="clearFilterDataUponColumnChange(index)"
                                                        required
                                                        :rules="[rules.required]"
                                                        dense
                                                        v-model="addedFilters[index].column"
                                                        :items="filteredSelectedColumns"
                                                        label="column"
                                                    ></v-select>
                                                </v-col>
                                                <v-col v-if="addedFilters[index].column" cols="2">
                                                    <v-select
                                                        dense
                                                        :items="columnFilters(filter.column)"
                                                        v-model="addedFilters[index].filter"
                                                        class="mr-2"
                                                        label="condition"
                                                        required
                                                        :rules="[rules.required]"
                                                    ></v-select>
                                                </v-col>
                                                <v-col
                                                    v-if="
                                                        addedFilters[index].column &&
                                                            addedFilters[index].filter &&
                                                            ['is null', 'is not null'].includes(addedFilters[index].filter) === false &&
                                                            (['DATE', 'TIME', 'TIMESTAMP_NTZ'].includes(colType(addedFilters[index].column)) &&
                                                                addedFilters[index].filter !== 'in') === false &&
                                                            addedFilters[index].file === null
                                                    "
                                                    class="mr-2"
                                                    cols="2"
                                                >
                                                    <v-text-field
                                                        :rules="
                                                            colType(addedFilters[index].column) === 'NUMBER' && addedFilters[index].filter !== 'in'
                                                                ? [rules.required, rules.mustBeInteger]
                                                                : colType(addedFilters[index].column) === 'FLOAT' && addedFilters[index].filter !== 'in'
                                                                ? [rules.required, rules.mustBeFloat]
                                                                : [rules.required]
                                                        "
                                                        required
                                                        autocomplete="new-value"
                                                        :label="addedFilters[index].filter == 'in' ? 'comma seperated values' : 'value'"
                                                        v-model="addedFilters[index].inputField1"
                                                        dense
                                                    ></v-text-field>
                                                </v-col>
                                                <v-col
                                                    v-if="
                                                        addedFilters[index].column &&
                                                            addedFilters[index].filter &&
                                                            addedFilters[index].filter === 'between' &&
                                                            ['NUMBER', 'FLOAT'].includes(colType(addedFilters[index].column))
                                                    "
                                                    cols="1"
                                                >
                                                    <div style="height:48px" class="d-flex align-center justify-center mx-1">
                                                        <span class="text-uppercase">and</span>
                                                    </div>
                                                </v-col>
                                                <v-col
                                                    v-if="
                                                        addedFilters[index].column &&
                                                            addedFilters[index].filter &&
                                                            addedFilters[index].filter === 'between' &&
                                                            ['NUMBER', 'FLOAT'].includes(colType(addedFilters[index].column))
                                                    "
                                                    cols="2"
                                                >
                                                    <v-text-field
                                                        required
                                                        :rules="
                                                            colType(addedFilters[index].column) === 'NUMBER' && addedFilters[index].filter !== 'in'
                                                                ? [rules.required, rules.mustBeInteger]
                                                                : colType(addedFilters[index].column) === 'FLOAT' && addedFilters[index].filter !== 'in'
                                                                ? [rules.required, rules.mustBeFloat]
                                                                : [rules.required]
                                                        "
                                                        label="value"
                                                        v-model="addedFilters[index].inputField2"
                                                        dense
                                                    ></v-text-field>
                                                </v-col>
                                                <v-col
                                                    v-if="addedFilters[index].column && addedFilters[index].filter && addedFilters[index].filter == 'in'"
                                                    cols="1"
                                                >
                                                    <v-tooltip bottom>
                                                        <template v-slot:activator="{ on }">
                                                            <div v-on="on" class="d-flex align-center">
                                                                <v-file-input
                                                                    dense
                                                                    v-model="addedFilters[index].file"
                                                                    @change="uploadFile($event, index)"
                                                                    accept=".txt"
                                                                    label="Upload file"
                                                                    :hide-input="addedFilters[index].file === null"
                                                                ></v-file-input>
                                                            </div>
                                                        </template>
                                                        <span>Upload a plain text file (.txt), having one unquoted value per line. </span>
                                                    </v-tooltip>
                                                </v-col>
                                                <v-col
                                                    v-if="
                                                        addedFilters[index].column &&
                                                            addedFilters[index].filter &&
                                                            ['is null', 'is not null', 'in'].includes(addedFilters[index].filter) === false &&
                                                            ['DATE', 'TIMESTAMP_NTZ'].includes(colType(addedFilters[index].column))
                                                    "
                                                    class="mr-2"
                                                    cols="1"
                                                >
                                                    <v-menu :close-on-content-click="false" transition="scale-transition" offset-y min-width="290px">
                                                        <template v-slot:activator="{ on }">
                                                            <v-text-field
                                                                v-model="addedFilters[index].datePicker1"
                                                                :label="addedFilters[index].filter === 'between' ? 'Start date' : 'date'"
                                                                readonly
                                                                v-on="on"
                                                                dense
                                                                :rules="[rules.required]"
                                                                required
                                                            ></v-text-field>
                                                        </template>
                                                        <v-date-picker v-model="addedFilters[index].datePicker1" no-title scrollable></v-date-picker>
                                                    </v-menu>
                                                </v-col>
                                                <v-col
                                                    v-if="
                                                        addedFilters[index].column &&
                                                            addedFilters[index].filter &&
                                                            ['is null', 'is not null', 'in'].includes(addedFilters[index].filter) === false &&
                                                            ['TIME', 'TIMESTAMP_NTZ'].includes(colType(addedFilters[index].column))
                                                    "
                                                    cols="1"
                                                >
                                                    <v-menu :close-on-content-click="false" transition="scale-transition" offset-y min-width="290px">
                                                        <template v-slot:activator="{ on }">
                                                            <v-text-field
                                                                v-model="addedFilters[index].timePicker1"
                                                                :label="addedFilters[index].filter === 'between' ? 'Start time' : 'time'"
                                                                readonly
                                                                v-on="on"
                                                                dense
                                                                :rules="[rules.required]"
                                                                required
                                                            ></v-text-field>
                                                        </template>
                                                        <v-time-picker
                                                            v-model="addedFilters[index].timePicker1"
                                                            use-seconds
                                                            format="24hr"
                                                            scrollable
                                                        ></v-time-picker>
                                                    </v-menu>
                                                </v-col>
                                                <v-col
                                                    v-if="
                                                        addedFilters[index].column &&
                                                            addedFilters[index].filter &&
                                                            ['DATE', 'TIMESTAMP_NTZ', 'TIME'].includes(colType(addedFilters[index].column)) &&
                                                            addedFilters[index].filter === 'between'
                                                    "
                                                    cols="1"
                                                >
                                                    <div style="height:48px" class="d-flex align-center justify-center mx-1">
                                                        <span class="text-uppercase">and</span>
                                                    </div>
                                                </v-col>
                                                <v-col
                                                    v-if="
                                                        addedFilters[index].column &&
                                                            addedFilters[index].filter &&
                                                            ['DATE', 'TIMESTAMP_NTZ'].includes(colType(addedFilters[index].column)) &&
                                                            addedFilters[index].filter === 'between'
                                                    "
                                                    cols="1"
                                                >
                                                    <v-menu :close-on-content-click="false" transition="scale-transition" offset-y min-width="290px">
                                                        <template v-slot:activator="{ on }">
                                                            <v-text-field
                                                                v-model="addedFilters[index].datePicker2"
                                                                label="End date"
                                                                readonly
                                                                v-on="on"
                                                                dense
                                                                :rules="[rules.required]"
                                                                required
                                                            ></v-text-field>
                                                        </template>
                                                        <v-date-picker v-model="addedFilters[index].datePicker2" no-title scrollable></v-date-picker>
                                                    </v-menu>
                                                </v-col>
                                                <v-col
                                                    v-if="
                                                        addedFilters[index].column &&
                                                            addedFilters[index].filter &&
                                                            ['TIME', 'TIMESTAMP_NTZ'].includes(colType(addedFilters[index].column)) &&
                                                            addedFilters[index].filter === 'between'
                                                    "
                                                    class="ml-2"
                                                    cols="1"
                                                >
                                                    <v-menu :close-on-content-click="false" transition="scale-transition" offset-y min-width="290px">
                                                        <template v-slot:activator="{ on }">
                                                            <v-text-field
                                                                v-model="addedFilters[index].timePicker2"
                                                                label="End time"
                                                                readonly
                                                                v-on="on"
                                                                dense
                                                                :rules="[rules.required]"
                                                                required
                                                            ></v-text-field>
                                                        </template>
                                                        <v-time-picker
                                                            use-seconds
                                                            v-model="addedFilters[index].timePicker2"
                                                            format="24hr"
                                                            scrollable
                                                        ></v-time-picker>
                                                    </v-menu>
                                                </v-col>
                                            </v-row>
                                        </div>
                                    </v-form>
                                </div>
                            </div>
                        </div>
                        <v-btn :disabled="addedFilters && areFiltersValid === false" color="secondary" @click="step = 3">continue</v-btn>
                        <v-btn @click="step = 1" text>back</v-btn>
                    </v-stepper-content>
                    <v-stepper-step color="secondary" :complete="step > 3" step="3">Row limit</v-stepper-step>
                    <v-stepper-content step="3">
                        <v-form class="mb-3" v-model="validRowNumberForm">
                            <v-text-field
                                outlined
                                dense
                                label="Specify the number of records to return"
                                required
                                v-model="numberRows"
                                :rules="[rules.required, rules.maxAndIntQueryRows]"
                            ></v-text-field>
                        </v-form>
                        <v-btn @click="step = 4" :disabled="validRowNumberForm === false" color="secondary">Continue</v-btn>
                        <v-btn @click="step = 2" text>back</v-btn>
                    </v-stepper-content>
                    <v-stepper-step color="secondary" step="4">Review Query</v-stepper-step>
                    <v-stepper-content step="4">
                        <pre class="pb-5 pt-2" v-text="query"></pre>
                        <QuerySubmit
                            mode="PREVIEW"
                            buttonName="submit preview query"
                            :iconShaped="false"
                            @success="closeDialogAndChangeTab()"
                            :forDialog="true"
                            :query="query"
                        ></QuerySubmit>

                        <v-btn @click="step = 3" text>back</v-btn>
                    </v-stepper-content>
                </v-stepper>
            </v-card-text>
        </v-card>
    </v-dialog>
</template>
<script>
import { mapState } from 'vuex'

const QuerySubmit = () => import('../components/TheSnapshotSubmitQuery')

export default {
    name: 'QueryDialog',
    components: { QuerySubmit },
    data: function() {
        return {
            queryDialog: false,
            step: 1,
            addedFilters: [],
            filterIndex: 0,
            columnSearch: '',
            validRowNumberForm: true,
            validFilters: false,
            fetchingTableColumnsForQuery: false,
            numberRows: 10000,
            queryColumnsList: [],
            rules: {
                required: value => !!value || 'Required.',
                maxAndIntQueryRows: value => (!!parseInt(value) && value >= 0 && value <= 10000000) || 'Please insert a number between 1 and 10000000 rows',
                mustBeInteger: value => Number(value) === parseInt(value) || 'Must be an integer',
                mustBeFloat: value => Number(value) === parseFloat(value) || 'Must be a float'
            },
            selectAllColumns: false,
            filters: ['equal', 'not equal', 'greater than', 'less than', 'less than or equal', 'greater than or equal', 'between', 'in'],
            selectedTable: null,
            selectedColumns: [],
            operators: {
                'greater than': '>',
                'less than': '<',
                equal: '=',
                'not equal': '!=',
                in: 'in',
                between: 'between',
                'less than or equal': '<=',
                'greater than or equal': '>='
            }
        }
    },
    props: {
        tableName: String,
        tableId: Number,
        listItem: Boolean,
        setDataFromLastQuery: Boolean,
        outlinedActivationButton: { type: Boolean, default: false },
        smallActivationButton: { type: Boolean, default: true },
        buttonName: { type: String, default: 'query' }
    },
    computed: {
        ...mapState('snapshotStore', ['latestQueryBuilderQueryData']),
        allColumns() {
            if (this.$data.queryColumnsList && this.$data.queryColumnsList.length) {
                const columnsData = this.$data.queryColumnsList.map(function(column) {
                    const filteredData = {
                        colName: column.short_id,
                        type: column.coltype.includes('(') ? column.coltype.substring(0, column.coltype.indexOf('(')) : column.coltype,
                        desc: column.description ? column.description : 'No description available'
                    }
                    return filteredData
                })
                return columnsData
            }
            return []
        },
        filteredSelectedColumns() {
            if (this.selectAllColumns === true && this.allColumns) {
                return this.allColumns.map(column => column.colName)
            } else if (this.selectAllColumns === false) {
                return this.selectedColumns
            }
            return []
        },
        computedDateFormatted() {
            return this.formatDate(this.date)
        },
        areFiltersValid() {
            const isInvalidFilter = filter => filter.valid === false
            if (this.$data.addedFilters && this.$data.addedFilters.some(isInvalidFilter) === false) {
                return true
            }
            return false
        },
        query() {
            const selectStatement = this.$data.selectAllColumns
                ? 'SELECT *' + `\nFROM "${this.$data.selectedTable}"`
                : `SELECT ${this.filteredSelectedColumns.map(col => `"${col}"`).join(', ')}` + `\nFROM "${this.$data.selectedTable}"`
            if (this.$data.addedFilters.length > 0) {
                const conditionsStatement = this.$data.addedFilters.map(filter => {
                    if (
                        ['equal', 'not equal', 'greater than', 'less than', 'greater than or equal', 'less than or equal'].includes(filter.filter) &&
                        ['NUMBER', 'FLOAT'].includes(this.colType(filter.column))
                    ) {
                        const statement = `"${filter.column}" ${this.$data.operators[filter.filter]} ${filter.inputField1}`
                        return statement
                    } else if (['equal', 'not equal'].includes(filter.filter) && filter.inputField1 && this.colType(filter.column) === 'VARCHAR') {
                        const statement = `"${filter.column}" ${this.$data.operators[filter.filter]} '${filter.inputField1.replace(/'/g, "''")}'`
                        return statement
                    } else if (
                        ['equal', 'not equal', 'greater than', 'less than', 'greater than or equal', 'less than or equal'].includes(filter.filter) &&
                        this.colType(filter.column) === 'DATE'
                    ) {
                        const statement = `"${filter.column}" ${this.$data.operators[filter.filter]} '${filter.datePicker1}'`
                        return statement
                    } else if (filter.filter === 'between' && this.colType(filter.column) === 'DATE') {
                        const statement = `"${filter.column}" ${this.$data.operators[filter.filter]} '${filter.datePicker1}' and '${filter.datePicker2}'`
                        return statement
                    } else if (
                        ['equal', 'not equal', 'greater than', 'less than', 'greater than or equal', 'less than or equal'].includes(filter.filter) &&
                        this.colType(filter.column) === 'TIME'
                    ) {
                        const statement = `"${filter.column}" ${this.$data.operators[filter.filter]} '${filter.timePicker1}'`
                        return statement
                    } else if (filter.filter === 'between' && this.colType(filter.column) === 'TIME') {
                        const statement = `"${filter.column}" ${this.$data.operators[filter.filter]} '${filter.timePicker1}' and '${filter.timePicker2}'`
                        return statement
                    } else if (
                        ['equal', 'not equal', 'greater than', 'less than', 'greater than or equal', 'less than or equal'].includes(filter.filter) &&
                        this.colType(filter.column) === 'TIMESTAMP_NTZ'
                    ) {
                        const statement = `"${filter.column}" ${this.$data.operators[filter.filter]} '${filter.datePicker1} ${filter.timePicker1}'`
                        return statement
                    } else if (filter.filter === 'between' && this.colType(filter.column) === 'TIMESTAMP_NTZ') {
                        const statement = `"${filter.column}" ${this.$data.operators[filter.filter]} '${filter.datePicker1} ${filter.timePicker1}' and '${
                            filter.datePicker2
                        } ${filter.timePicker2}'`
                        return statement
                    } else if (filter.filter === 'between' && ['NUMBER', 'FLOAT'].includes(this.colType(filter.column))) {
                        const statement = `"${filter.column}" ${this.$data.operators[filter.filter]} ${filter.inputField1} and ${filter.inputField2}`
                        return statement
                    } else if (filter.filter === 'in' && filter.file) {
                        const statement = `"${filter.column}" ${this.$data.operators[filter.filter]} (${filter.fileContent})`
                        return statement
                    } else if (filter.filter === 'in' && filter.inputField1 && !filter.file && ['NUMBER', 'FLOAT'].includes(this.colType(filter.column))) {
                        const statement = `"${filter.column}" ${this.$data.operators[filter.filter]} (${filter.inputField1})`
                        return statement
                    } else if (
                        filter.filter === 'in' &&
                        filter.inputField1 &&
                        !filter.file &&
                        ['NUMBER', 'FLOAT'].includes(this.colType(filter.column)) === false
                    ) {
                        const statement = `"${filter.column}" ${this.$data.operators[filter.filter]} (${filter.inputField1
                            .split(',')
                            .map(val => `'${val.replace(/'/g, "''")}'`)
                            .join(', ')})`
                        return statement
                    } else if (['is null', 'is not null'].includes(filter.filter)) {
                        const statement = `"${filter.column}" ${filter.filter}`
                        return statement
                    }
                })
                return selectStatement + '\nWHERE ' + conditionsStatement.join('\nAND ') + `\nlimit ${this.$data.numberRows}`
            }
            return selectStatement + `\nlimit ${this.$data.numberRows}`
        },
        columnData() {
            if (this.$data.columnSearch && this.allColumns) {
                return this.allColumns.filter(
                    column =>
                        column.colName.toLowerCase().includes(this.$data.columnSearch.toLowerCase()) ||
                        column.desc.toLowerCase().includes(this.$data.columnSearch.toLowerCase())
                )
            }
            return this.allColumns
        }
    },
    methods: {
        closeDialogAndChangeTab() {
            const queryData = {
                table: this.$data.selectedTable,
                columns: this.filteredSelectedColumns.length === this.allColumns.length ? 'all' : this.filteredSelectedColumns,
                tableId: this.$props.tableId ? this.$props.tableId : this.latestQueryBuilderQueryData.tableId ? this.latestQueryBuilderQueryData.tableId : null,
                filters: this.$data.addedFilters,
                rowLimit: this.$data.numberRows,
                query: this.query
            }
            this.$store.dispatch('snapshotStore/setLatestQueryBuilderQueryData', queryData)
            this.$data.queryDialog = false
            this.$emit('querySubmitSuccess')
        },
        loadTextFromFile(ev) {
            const file = ev.target.files[0]
            const reader = new FileReader()
            reader.onload = e => this.setFileContentForFilter(e.target.result)
            reader.readAsText(file)
        },
        fetchColumnsForQuery: function(tid) {
            if (tid) {
                this.$data.fetchingTableColumnsForQuery = true
                this.$axios
                    .get(`/tables/${tid}/columns`)
                    .then(response => {
                        this.$data.queryColumnsList = response.data
                    })
                    .catch(() => {
                        this.$store.dispatch('showSnackBar', {
                            snackBarText: 'Failed to fetch table column data, try refreshing the page.',
                            snackBarIcon: 'error'
                        })
                    })
                    .finally(() => {
                        this.$data.fetchingTableColumnsForQuery = false
                    })
            }
        },
        uploadFile(evt, filterIdx) {
            if (evt !== undefined && evt !== null) {
                var reader = new FileReader()
                reader.readAsText(evt)
                reader.onload = () => {
                    this.setFileContentForFilter(reader.result, filterIdx)
                }
            } else {
                this.$data.addedFilters[filterIdx].fileContent = null
                this.$data.addedFilters[filterIdx].file = null
                this.$data.addedFilters[filterIdx].inputField1 = null
            }
        },
        setFileContentForFilter(fileData, filterIdx) {
            const filter = this.$data.addedFilters[filterIdx]
            var formatterData
            if (filter && ['NUMBER', 'FLOAT'].includes(this.colType(filter.column))) {
                formatterData = fileData.split('\n').map(dataPoint => parseInt(dataPoint))
            } else {
                formatterData = fileData.split('\n').map(dataPoint => `'${dataPoint}'`)
            }
            this.$data.addedFilters[filterIdx].fileContent = formatterData
            this.$data.addedFilters[filterIdx].inputField1 = formatterData
        },
        addFilter() {
            const id = this.$data.filterIndex
            this.$data.addedFilters.push({
                index: id,
                column: null,
                filter: null,
                inputField1: null,
                inputField2: null,
                datePicker1: null,
                datePicker2: null,
                timePicker1: null,
                timePicker2: null,
                file: null,
                fileContent: null,
                valid: false
            })
            this.$data.filterIndex += 1
        },
        removeFilter(filterIndex) {
            this.$data.addedFilters = this.$data.addedFilters.filter(filter => filter.index !== filterIndex)
        },
        clearFilterDataUponColumnChange(filterArrayIndex) {
            this.$data.addedFilters[filterArrayIndex].filter = null
            this.$data.addedFilters[filterArrayIndex].inputField1 = null
            this.$data.addedFilters[filterArrayIndex].inputField2 = null
            this.$data.addedFilters[filterArrayIndex].datePicker1 = null
            this.$data.addedFilters[filterArrayIndex].datePicker2 = null
            this.$data.addedFilters[filterArrayIndex].timePicker1 = null
            this.$data.addedFilters[filterArrayIndex].timePicker2 = null
            this.$data.addedFilters[filterArrayIndex].fileContent = null
        },
        formatDate(date) {
            if (!date) return null
            const [year, month, day] = date.split('-')
            return `${month}/${day}/${year}`
        },
        colType(col) {
            if (col && this.allColumns) {
                const colData = this.allColumns.find(item => item.colName === col)
                if (colData) {
                    return colData.type
                } else {
                    return null
                }
            }
        },
        columnFilters(col) {
            if (col !== null && col !== undefined && this.allColumns) {
                const colData = this.allColumns.find(item => item.colName === col)
                if (colData && ['NUMBER', 'DATE', 'TIME', 'TIMESTAMP_NTZ', 'FLOAT'].includes(colData.type) === true) {
                    return [
                        'equal',
                        'not equal',
                        'greater than',
                        'less than',
                        'greater than or equal',
                        'less than or equal',
                        'between',
                        'in',
                        'is null',
                        'is not null'
                    ]
                } else if (colData && colData.type === 'VARCHAR') {
                    return ['equal', 'not equal', 'in', 'is null', 'is not null']
                } else if (colData) {
                    return ['equal', 'not equal', 'in', 'is null', 'is not null']
                }
            } else {
                return []
            }
        }
    },
    mounted() {
        this.$data.selectedTable = this.$props.tableName
        if (this.$props.setDataFromLastQuery === true && this.latestQueryBuilderQueryData && this.latestQueryBuilderQueryData.table !== null) {
            this.fetchColumnsForQuery(this.latestQueryBuilderQueryData.tableId)
            this.$data.selectedTable = this.latestQueryBuilderQueryData.table
            if (this.latestQueryBuilderQueryData.columns === 'all') {
                this.$data.selectAllColumns = true
                this.$data.selectedColumns = this.filteredSelectedColumns
            } else {
                this.$data.selectedColumns = this.latestQueryBuilderQueryData.columns
            }
            this.$data.numberRows = this.latestQueryBuilderQueryData.rowLimit
            if (this.latestQueryBuilderQueryData.filters) {
                const filterIds = this.latestQueryBuilderQueryData.filters.map(filter => filter.index)
                this.$data.filterIndex = Math.max(...filterIds) + 1
                this.$data.addedFilters = this.latestQueryBuilderQueryData.filters
            }
        }
    },
    watch: {
        selectAllColumns: function(nextVal) {
            if (nextVal === true) {
                this.$data.selectedColumns = this.filteredSelectedColumns
            } else if (nextVal === false) {
                this.$data.selectedColumns = []
            }
        },
        selectedColumns: function(nextVal) {
            if ((nextVal && nextVal.length) || (nextVal && !nextVal.length && this.selectAllColumns === false)) {
                this.$data.addedFilters = this.$data.addedFilters.filter(filter => nextVal.includes(filter.column))
            }
        },
        queryDialog: function(nextVal, preVal) {
            if (preVal === true && nextVal === false) {
                this.$data.step = 1
                this.$emit('dialogClosed')
            } else if (preVal === false && nextVal === true) {
                this.$emit('dialogOpened')
            }
        },
        allColumns: function(nextVal) {
            if (nextVal && nextVal.length && this.$data.selectAllColumns) {
                this.$data.selectedColumns = nextVal.map(column => column.colName)
            }
        }
    }
}
</script>
